import React from "react";
import { useTranslate } from "@pankod/refine-core";
import {
  useDataGrid,
  DataGrid,
  GridColumns,
  List,
  BooleanField,
  Stack,
  EditButton,
} from "@pankod/refine-mui";

import { IClient } from "interfaces";

export const ClientList: React.FC = () => {
  const t = useTranslate();

  const { dataGridProps } = useDataGrid<IClient>();

  const columns = React.useMemo<GridColumns<IClient>>(
    () => [
      {
        field: "name",
        headerName: t("clients.fields.name"),
        flex: 1,
      },
      {
        field: "email",
        headerName: t("clients.fields.email"),
        flex: 1,
      },
      {
        field: "phone",
        headerName: t("clients.fields.phone"),
        flex: 1,
      },
      {
        field: "sampleType",
        headerName: t("clients.fields.sample"),
        flex: 1,
      },
      {
        field: "weeklyAnalysis",
        headerName: t("clients.fields.weekly"),
        flex: 1,
      },
      {
        field: "isActive",
        headerName: t("clients.fields.active"),
        minWidth: 120,
        flex: 0.3,
        renderCell: ({ row }) => {
          return (
            <BooleanField value={row.isActive} />
          )
        }
      },
      {
        field: "actions",
        type: "actions",
        headerName: t("table.actions"),
        renderCell: function render({ row }) {
          return (
            <Stack direction="row" spacing={1}>
              <EditButton size="small" hideText recordItemId={row.id} />
            </Stack>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 80,
      },
    ],
    [t]
  );

  return (
    <List>
      <DataGrid {...dataGridProps} columns={columns} autoHeight />
    </List>
  );
};
