import { useTranslate, HttpError } from "@pankod/refine-core";
import {
  Edit,
  Box,
  TextField,
  FormControlLabel,
  Switch,
} from "@pankod/refine-mui";
import { useForm } from "@pankod/refine-react-hook-form";

import { IClient } from "interfaces";
import { useEffect, useState } from "react";

export const ClientEdit: React.FC = () => {
  const t = useTranslate();
  const {
    saveButtonProps,
    refineCore: { queryResult },
    register,
    formState: { errors },
  } = useForm<IClient, HttpError, IClient>();
  const [isActive, setIsActive] = useState<boolean>();

  const client: IClient | undefined = queryResult?.data?.data;

  useEffect(() => {
    if (client) {
      setIsActive(client.isActive);
    }
  }, [client, setIsActive]);

  if (!client) return <></>;

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <TextField
          {...register("name", {
            required: t("errors.required.field", { field: "Nombre" }),
          })}
          error={!!errors.name}
          helperText={errors.name?.message}
          margin="normal"
          fullWidth
          name="name"
          label={t('clients.fields.name')}
        />
        <TextField
          {...register("email", {
            required: t("errors.required.field", { field: "Correo" }),
          })}
          error={!!errors.email}
          helperText={errors.email?.message}
          margin="normal"
          fullWidth
          name="email"
          label={t('clients.fields.email')}
        />
        <TextField
          {...register("phone", {
            required: t("errors.required.field", { field: "Telefono" }),
          })}
          error={!!errors.phone}
          helperText={errors.phone?.message}
          margin="normal"
          fullWidth
          name="phone"
          label={t('clients.fields.phone')}
        />
        <TextField
          {...register("sampleType", {
            required: t("errors.required.field", { field: "Muestreo" }),
          })}
          error={!!errors.sampleType}
          helperText={errors.sampleType?.message}
          margin="normal"
          fullWidth
          name="sampleType"
          label={t('clients.fields.sample')}
        />
        <TextField
          {...register("weeklyAnalysis", {
            required: t("errors.required.field", { field: "Muestras semanales" }),
          })}
          error={!!errors.weeklyAnalysis}
          helperText={errors.weeklyAnalysis?.message}
          margin="normal"
          fullWidth
          name="weeklyAnalysis"
          label={t('clients.fields.weekly')}
        />
        <FormControlLabel 
          control={<Switch {...register('isActive')} name="isActive" checked={isActive} onChange={(e) => setIsActive(e.target.checked)}/>}
          label={t('clients.fields.active')}
        />
        <TextField
          {...register("code")}
          margin="normal"
          fullWidth
          name="code"
          label={t('clients.fields.code')}
        />
      </Box>
    </Edit>
  );
};
